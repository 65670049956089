@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');

*{
  padding:0;
  margin: 0;
  box-sizing: border-box;
}
.home{
  min-height: 100vh;
  background-color: #ffffff;
  margin-top: 70px;
  
}
.home-content{
  display: flex;
  flex-direction: column;
  /* min-height: 100%; */
  position:absolute;
  margin-top:6rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: rgb(15, 15, 15);
  font-family: 'Poppins';
  font-weight: 600;
  /* font-size:35px; */
  }
  #hello{
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
  padding: 10px;
  font-size: 30px;
  
}
#name{
  /* color: #00e5fe; */
  color:#3399ff;
  font-family: 'Allura';
  font-size: 55px;

  /* font-family: "Poppins", sans-serif; */
}
#intro{
  color: #000000;
  /* font-size: 31px; */
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  }
  #job{
    /* flex-wrap: nowrap; */
    font-size: 33px;
    /* text-wrap:nowrap; */
}

.social-icons{
  display: flex;
  justify-content: center;
  margin-top: 10px;
  gap: 20px;
}
.social-icon {
  position: relative;
  margin: 0 10px;
  font-size: 3rem;
  /* color: #333; */
  transition: color 0.3s ease;
  
}
.social-icon:hover{
  color: #333;
}
/* .twitter:hover{
  color: #069aaa;
}
.instagram:hover{
  color:red;
}
.github:hover{
  color:black;
}
.linkedin:hover{
  color:#0192cc;
} */
#twitter{
  color: #069aaa;
}
#instagram{
  color:#e92ba0;
}
#github{
  color:black;
}
#linkedin{
  color:#0192cc;
}

.scrolldownbutton{
  margin-top: 80px;
}
.scrollbtn{
  /* background-color: #00e5fe; */
  /* background-color: #646cff; */
  background-color: #3399ff;
  width: 200px;
  color: black;
  padding: 20px;
  border-radius: 20px;
}
@media screen and (max-width:768px) {
    #job{
      font-size: 25px;
       color: #0a0a0a;
    }
  
}

@media screen and (max-width:340px) {
  #job{
    font-size:23px;
  }
  .social-icon{
    font-size: 2rem;
  }
}