* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.projects {
  min-height: 100vh;
  background-color: rgb(235, 141, 18);
  padding: 20px;
}

.header {
  text-align: center;
  margin-bottom: 20px;
  color: #fff;
}

.projects-container {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.card {
  max-width: 345px;
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.card-media {
  flex: 1;
}

.card-content, .card-actions {
  flex: 0;
}

.card-heading {
  font-size: 1.25rem; 
}

.sub-heading {
  font-size: 1rem; 
}

@media screen and (max-width: 780px) {
  .projects-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .card {
    height: 300px;
  }
.card-content  .card-heading {
    font-size: 1rem; 
    white-space: nowrap;
    font-weight: 500;
  }
 .card-content .sub-heading{
    font-size: 0.75rem; 
    font-weight: 200;
  }
}
